


















import Vue from 'vue'
import Component from 'vue-class-component'
import PackPopup from '@/components/SAdmin/PackPopup.vue'

@Component({
  components: {
    PackPopup
  }
})
export default class Packs extends Vue {
    public actionPack = null
    public picklPacks = [];
    getSubscriptionPacks () {
      this.$http
        .get('subscriptions')
        .then((response:any) => {
          this.picklPacks = response.body.data
        }
        )
    }
    mounted () {
      this.getSubscriptionPacks()
    }
}
