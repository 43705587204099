







































































































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class PackPopup extends Vue {
  @Prop() popupId!: string;
  @Prop() pack!: any;
  public payload = {
    name: '',
    price: '',
    pickl_credits: ''
  };

  submitPack () {
    this.$validator.validateAll('pack-form').then(result => {
      if (result) {
        let popupCloseButton: HTMLElement = this.$refs
          .popupCloseButton as HTMLElement
        this.$store
          .dispatch('editPicklPack', { id: this.pack.id, data: this.payload })
          .then(
            () => {
              this.$emit('updated')
              this.$notify({
                text: `PICKL Pack edited and saved successfully`,
                type: 'success'
              })
              popupCloseButton.click()
            },
            () => popupCloseButton.click()
          )
      }
    })
  }

  @Watch('pack')
  onPackChange (value: any, oldValue: any) {
    this.payload = value
  }
}
